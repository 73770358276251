<template>
  <header id="head">
    <div class="container">
      <div class="logo">
        <img src="../assets/Zimam-logo2.png" />
        <span class="title">{{ $t("Navbar.CompanyName") }}</span>
      </div>
      <div class="links">
        <ul class="list">
          <li class="nav-item">
            <a href="/" class="nav-link">{{ $t("Navbar.Link1") }}</a>
          </li>
          <li class="nav-item">
            <a href="#About" class="nav-link">{{ $t("Navbar.Link3") }}</a>
          </li>
          <li class="nav-item">
            <a href="http://support.zimamsys.com" class="nav-link">{{
              $t("Navbar.Link4")
            }}</a>
          </li>
          <li class="nav-item" v-if="isEnglish">
            <a href="/ar" class="nav-link">العربية</a>
          </li>
          <li class="nav-item" v-if="isArabic">
            <a href="/en" class="nav-link">EN</a>
          </li>
        </ul>
        <button class="show_nav" v-on:click="openNav()">
          <div class="line1"></div>
          <div class="line2"></div>
          <div class="line3"></div>
        </button>
      </div>
    </div>
    <div class="mobile-nav">
      <ul>
        <li class="nav-item">
          <a href="/" class="nav-link">{{ $t("Navbar.Link1") }}</a>
        </li>
        <li class="nav-item">
          <a href="#About" class="nav-link">{{ $t("Navbar.Link3") }}</a>
        </li>

        <li class="nav-item">
          <a href="http://support.zimamsys.com" class="nav-link">{{
            $t("Navbar.Link4")
          }}</a>
        </li>
        <li class="nav-item" v-if="isEnglish">
          <a href="/ar" class="nav-link">العربية</a>
        </li>
        <li class="nav-item" v-if="isArabic">
          <a href="/en" class="nav-link">EN</a>
        </li>
      </ul>
    </div>
  </header>
</template>

<script>
export default {
  data() {
    return {
      isArabic: false,
      isEnglish: false,
    };
  },
  async mounted() {
    if (
      location.href.split("/")[3] == "ar" ||
      location.href.split("/")[3].split("#")[0] == "ar"
    ) {
      this.isArabic = true;
      this.isEnglish = false;
    } else {
      this.isEnglish = true;
      this.isArabic = false;
    }
  },
  methods: {
    openNav() {
      let bar = document.getElementById("head");
      let nav = document.getElementsByClassName("mobile-nav")[0];
      if (nav.classList[1] != "show") {
        nav.classList.add("show");
        bar.classList.add("border");
      } else {
        nav.classList.remove("show");
        bar.classList.remove("border");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
header {
  z-index: 3000;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  width: 1000px;
  height: 80px;
  margin-top: 2rem;
  background-color: #1f2833;
  border-radius: 360px;
  color: #eeeeee;
  box-shadow: 0px 0px 25px 15px rgba(0, 0, 0, 0.1);
}
.container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
}
.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 40px;
    height: 40px;
  }
}
.title {
  font-size: 20px;
  margin: 1rem;
}
.list {
  width: 100%;
}
.nav-item {
  display: inline;
  margin: 2rem;
}
.nav-link {
  color: #eeeeee;
  transition: all 300ms ease-in-out;
  &:hover {
    color: #472bff;
  }
}

.show_nav {
  background: none;
  border: none;
  outline: none;
  direction: rtl;
  display: none;
  width: 40px;
  height: 40px;
  div {
    margin: 9px;
    text-align: right;
  }
}
.line1,
.line3 {
  width: 30px;
  height: 2px;
  background-color: #eeeeee;
  transition: all 200ms ease-in-out;
}
.line2 {
  width: 25px;
  height: 2px;
  background-color: #eeeeee;
  transition: all 200ms ease-in-out;
}
.show_nav:hover {
  cursor: pointer;
}
.mobile-nav {
  ul {
    display: none;
  }
  ul li {
    opacity: 0;
    padding: 10px;
  }
  li a {
    font-weight: 800;
    color: #eeeeee;
    transition: all 0.35s ease-out;
    &:hover {
      color: #472bff;
    }
  }
  ul li a {
    display: none;
  }
}
.show {
  background-color: #1f2833;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  ul {
    display: block;
  }
  ul li {
    opacity: 1;
  }
  ul li a {
    display: block;
  }
}
.border {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
@media screen and (max-width: 1057px) {
  header {
    width: 900px;
  }
  .list li {
    display: none;
  }
  .show_nav {
    display: block;
  }
}
@media screen and (max-width: 932px) {
  header {
    width: 700px;
  }
}
@media screen and (max-width: 736px) {
  header {
    width: 500px;
  }
}
</style>
