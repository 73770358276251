<template>
  <div class="home">
    <!--================================= Navbar Section =================================-->
    <NavBar />
    <!--================================= Welcome Section  =================================-->
    <div class="welcome" id="welcome_section">
      <div class="wel">
        <div class="wrapper">
          <div class="content">
            <h2>
              {{ $t("HomePage.Welcome_msg") }}
            </h2>
          </div>
        </div>
      </div>
    </div>
    <!--================================= Features Section  =================================-->
    <div id="features_section">
      <div class="features">
        <div class="feature">
          <div class="feature_content">
            <div class="icon"><i class="fa-solid fa-laptop-medical"></i></div>
            <div class="script">
              <h2>{{ $t("HomePage.Features.featureTitle1") }}</h2>
              {{ $t("HomePage.Features.featureScript1") }}
            </div>
            <div class="read">
              <a href="http://support.zimamsys.com" class="contactus_link">{{
                $t("HomePage.Features.featureButton")
              }}</a>
            </div>
          </div>
        </div>
        <div class="feature">
          <div class="feature_content">
            <div class="icon"><i class="fa-solid fa-hospital"></i></div>
            <div class="script">
              <h2>{{ $t("HomePage.Features.featureTitle2") }}</h2>
              {{ $t("HomePage.Features.featureScript2") }}
            </div>
            <div class="read">
              <a href="http://support.zimamsys.com" class="contactus_link">{{
                $t("HomePage.Features.featureButton")
              }}</a>
            </div>
          </div>
        </div>
        <div class="feature">
          <div class="feature_content">
            <div class="icon"><i class="fa-solid fa-dollar-sign"></i></div>
            <div class="script">
              <h2>{{ $t("HomePage.Features.featureTitle3") }}</h2>
              {{ $t("HomePage.Features.featureScript3") }}
            </div>
            <div class="read">
              <a href="http://support.zimamsys.com" class="contactus_link">{{
                $t("HomePage.Features.featureButton")
              }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--================================= Numbers Section  =================================-->
    <div class="numbers_section" id="Numbers">
      <h2>{{ $t("HomePage.Numbers.Title") }}</h2>
      <div class="boxes">
        <div class="box">
          <div class="box-content">
            <div>
              <div class="box-icon"><i class="fa-solid fa-users"></i></div>
              <span class="num">{{ NumberOfUsers }}</span>
              <p class="bcontent">{{ $t("HomePage.Numbers.Customers") }}</p>
            </div>
          </div>
        </div>
        <div class="box">
          <div class="box-content">
            <div>
              <div class="box-icon">
                <i class="fa-regular fa-envelope"></i>
              </div>
              <span class="num">{{ NumberOfTickets }}</span>
              <p class="bcontent">{{ $t("HomePage.Numbers.Tickets") }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--================================= About us Section  =================================-->
    <div class="aboutus_section" id="About">
      <div>
        <h2>{{ $t("HomePage.About.Title") }}</h2>
        <div class="sec1">
          <div class="image">
            <img src="../assets/Vision.svg" />
          </div>
          <div class="aboutus-content">
            <h2>{{ $t("HomePage.About.Vision") }}</h2>
            <article>
              <p>
                {{ $t("HomePage.About.VisionScript") }}
              </p>
            </article>
          </div>
        </div>
        <div class="sec1">
          <div class="aboutus-content">
            <h2>{{ $t("HomePage.About.Goals") }}</h2>
            <article>
              <p>
                {{ $t("HomePage.About.GoalsScript") }}
              </p>
            </article>
          </div>
          <div class="image">
            <img src="../assets/Goals.svg" />
          </div>
        </div>
      </div>
    </div>
    <!--================================= footer Section  =================================-->
    <div class="footer_section" id="Footer">
      <footer class="footer-distributed">
        <div class="footer-left">
          <h3>
            {{ $t("HomePage.Footer.Company") }}
            <span>{{ $t("HomePage.Footer.Name") }}</span>
          </h3>

          <p class="footer-links">
            <a href="/" class="link-1">{{ $t("HomePage.Footer.Link1") }}</a>

            <a href="#About">{{ $t("HomePage.Footer.Link2") }}</a>

            <a href="http://support.zimamsys.com">{{
              $t("HomePage.Footer.Link3")
            }}</a>
          </p>

          <p class="footer-company-name">
            {{ $t("HomePage.Footer.Copyright") }} ©
            {{ new Date().getFullYear() }}
          </p>
        </div>

        <div class="footer-center">
          <div>
            <i class="fa-solid fa-phone"></i>
            <p><a href="">+0597 128 184</a></p>
          </div>

          <div>
            <i class="fa fa-envelope"></i>
            <p><a href="">support@zimamsys.com</a></p>
          </div>
        </div>

        <div class="footer-right">
          <p class="footer-company-about">
            <span>{{ $t("HomePage.Footer.AboutTheCompany") }}</span>
            {{ $t("HomePage.Footer.AboutTheCompnayScript") }}
          </p>

          <div class="footer-icons">
            <a href="https://www.facebook.com/zimams"
              ><i class="fa fa-facebook"></i
            ></a>
          </div>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import NavBar from "../components/NavBarComp.vue";
import axios from "axios";
export default {
  name: "HomeView",
  components: {
    NavBar,
  },
  data() {
    return {
      NumberOfTickets: 0,
      NumberOfUsers: 0,
      url: "http://support.zimamsys.com",
    };
  },
  async mounted() {
    let body = document.getElementsByClassName("home")[0];
    let content = document.getElementsByClassName("aboutus-content");
    let featureScript = document.getElementsByClassName("script");
    if (
      location.href.split("/")[3] == "ar" ||
      location.href.split("/")[3].split("#")[0] == "ar"
    ) {
      body.classList.add("rtl");
      for (let i = 0; i < content.length; i++) {
        content[i].classList.add("text");
      }
      for (let i = 0; i < featureScript.length; i++) {
        featureScript[i].classList.add("text2");
      }
    }
    await axios.get(this.url + "/User/Zimam").then((res) => {
      this.NumberOfTickets = res.data.ticketsNumber.number;
      this.NumberOfUsers = res.data.usersNumber.number;
    });
  },
};
function reveal1() {
  var reveals = document.querySelectorAll(".box");

  for (var i = 0; i < reveals.length; i++) {
    var windowHeight = window.innerHeight;
    var elementTop = reveals[i].getBoundingClientRect().top;
    var elementVisible = 150;

    if (elementTop < windowHeight - elementVisible) {
      reveals[i].classList.add("active");
    } else {
      reveals[i].classList.remove("active");
    }
  }
}
function reveal2() {
  var reveals = document.querySelectorAll(".feature");

  for (var i = 0; i < reveals.length; i++) {
    var windowHeight = window.innerHeight;
    var elementTop = reveals[i].getBoundingClientRect().top;
    var elementVisible = 150;

    if (elementTop < windowHeight - elementVisible) {
      reveals[i].classList.add("active1");
    } else {
      reveals[i].classList.remove("active1");
    }
  }
}
window.addEventListener("scroll", reveal1);
window.addEventListener("scroll", reveal2);
</script>

<style lang="scss" scoped>
.rtl {
  direction: rtl;
}
/********************* Welcome Section *********************/
.welcome {
  height: 100vh;
  background-image: url(../assets/welcome2.jpg);
  background-position: center;
  -webkit-clip-path: polygon(50% 0%, 100% 0, 100% 100%, 50% 92%, 0 100%, 0 0);
  clip-path: polygon(50% 0%, 100% 0, 100% 100%, 50% 92%, 0 100%, 0 0);
}
.wel {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background: linear-gradient(
    180deg,
    rgba(16, 9, 143, 0.603) 0%,
    rgba(13, 13, 203, 0.397) 55%,
    rgba(0, 106, 255, 0.164) 100%
  );
}
.content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 650px;
  line-height: 35px;
  letter-spacing: 4px;
  height: 70vh;
  h2 {
    color: #eeeeee;
  }
}
@media screen and (max-width: 665px) {
  .content {
    width: 350px;
  }
}
@media screen and (max-width: 420px) {
  .content {
    width: 200px;
  }
}
/********************* Features Section *********************/
#features_section {
  display: flex;
  justify-content: center;
}
.features {
  display: grid;
  grid-template-columns: auto auto auto;
  transform: translateY(-90px);
}
.feature {
  margin: 1rem;
  z-index: 3000;
  width: 300px;
  height: 400px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 2px 2px 15px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 300ms ease-in-out;
  opacity: 0;
}
.active1 {
  transform: translateY(-30px);
  opacity: 1;
}
.feature:hover {
  color: #fff;
  background-color: #1f2833;
  transform: translateY(-30px);
}
.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  font-size: 32px;
  color: #472bff;
}
.script {
  text-align: left;
  width: 250px;
  height: 200px;
  h2 {
    text-align: center;
  }
}
.text2 {
  text-align: right;
}
.read {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  .contactus_link {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 300;
    width: 200px;
    height: 40px;
    color: #fff;
    border-radius: 10px;
    background-color: #472bff;
    transition: all 300ms ease-in-out;
    &:hover {
      cursor: pointer;
      color: #472bff;
      background-color: #fff;
    }
  }
}
@media screen and (max-width: 990px) {
  .features {
    grid-template-columns: auto auto;
  }
}
@media screen and (max-width: 680px) {
  .features {
    grid-template-columns: auto;
  }
}
/******************* Numbers Section *******************/
.numbers_section {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.boxes {
  display: grid;
  grid-template-columns: auto auto auto auto;
  margin-top: 3rem;
}
.box {
  margin: 2rem;
  width: 350px;
  height: 300px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  border-radius: 40px;
  opacity: 0;
  display: flex;
  justify-content: center;
  transition: all 300ms ease-in-out;
}
.active {
  opacity: 1;
}
.box-content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.box-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  color: #eeeeee;
  background-color: #472bff;
  border-radius: 100px;
  transform: translateY(-30px);
  transition: all 300ms ease-in-out;
  i {
    font-size: 42px;
  }
}
.num {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  font-size: 30px;
}
.bcontent {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  font-weight: 500;
  font-size: 18px;
}
.box:hover {
  color: #eeeeee;
  background-color: #472bff;
  transform: translateY(-15px);
  .box-icon {
    background-color: #fff;
    color: #472bff;
  }
}
@media screen and (max-width: 870px) {
  .boxes {
    display: grid;
    grid-template-columns: auto;
  }
}
/******************* About us Section *******************/
.aboutus_section {
  margin-top: 3.5rem;
}
.sec1 {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 2rem;
  opacity: 1;
  transition: all 300ms ease-in-out;
}
.image {
  width: 400px;
  height: 400px;
}
.aboutus-content {
  width: 400px;
  text-align: left;
  article {
    text-align: justify;
    color: #bbbbbb;
  }
}
.text {
  text-align: right;
}
@media screen and (max-width: 920px) {
  .aboutus_section {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .sec1 {
    display: block;
  }
}
@media screen and (max-width: 600px) {
  .image {
    width: 300px;
    height: 300px;
  }
  .aboutus-content {
    width: 300px;
    text-align: left;
  }
}

/******************* footer Section *******************/
#Footer {
  margin-top: 4rem;
}
.footer-distributed {
  background: #1f2833;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  width: 100%;
  font: bold 16px sans-serif;
  padding: 55px 50px;
}
.footer-distributed .footer-left,
.footer-distributed .footer-center,
.footer-distributed .footer-right {
  display: inline-block;
  vertical-align: top;
}
.footer-distributed .footer-left {
  width: 40%;
}
.footer-distributed h3 {
  color: #ffffff;
  font-weight: normal;
  font-size: 36px;
  margin: 0;
}
.footer-distributed h3 span {
  color: #472bff;
}
.footer-distributed .footer-links {
  color: #ffffff;
  margin: 20px 0 12px;
  padding: 0;
}
.footer-distributed .footer-links a {
  display: inline-block;
  line-height: 1.8;
  font-weight: 400;
  text-decoration: none;
  color: inherit;
}
.footer-distributed .footer-company-name {
  color: #7a7a7a;
  font-size: 14px;
  font-weight: normal;
  margin: 0;
}
.footer-distributed .footer-center {
  width: 35%;
  text-align: justify;
}
.footer-distributed .footer-center i {
  background-color: #33383b;
  color: #ffffff;
  font-size: 25px;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  text-align: center;
  line-height: 42px;
  margin: 10px 15px;
  vertical-align: middle;
}
.footer-distributed .footer-center i.fa-envelope {
  font-size: 17px;
  line-height: 38px;
}
.footer-distributed .footer-center p {
  display: inline-block;
  color: #ffffff;
  font-weight: 400;
  vertical-align: middle;
  margin: 0;
}
.footer-distributed .footer-center p span {
  display: block;
  font-weight: normal;
  font-size: 14px;
  line-height: 2;
}
.footer-distributed .footer-center p a {
  color: #472bff;
  text-decoration: none;
}
.footer-distributed .footer-links a:before {
  content: "|";
  font-weight: 300;
  font-size: 20px;
  left: 0;
  color: #fff;
  display: inline-block;
  padding-right: 5px;
}
.footer-distributed .footer-links .link-1:before {
  content: none;
}
.footer-distributed .footer-right {
  width: 20%;
}
.footer-distributed .footer-company-about {
  line-height: 20px;
  color: #92999f;
  font-size: 13px;
  font-weight: normal;
  margin: 0;
}
.footer-distributed .footer-company-about span {
  display: block;
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 20px;
}
.footer-distributed .footer-icons {
  margin-top: 25px;
}
.footer-distributed .footer-icons a {
  display: inline-block;
  width: 35px;
  height: 35px;
  cursor: pointer;
  background-color: #33383b;
  border-radius: 2px;

  font-size: 20px;
  color: #ffffff;
  text-align: center;
  line-height: 35px;

  margin-right: 3px;
  margin-bottom: 5px;
}

@media (max-width: 880px) {
  .footer-distributed {
    font: bold 14px sans-serif;
  }

  .footer-distributed .footer-left,
  .footer-distributed .footer-center,
  .footer-distributed .footer-right {
    display: block;
    width: 100%;
    margin-bottom: 40px;
    text-align: center;
  }

  .footer-distributed .footer-center i {
    margin-left: 0;
  }
}
</style>
